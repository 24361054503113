
import { Component, Ref, Vue } from 'vue-property-decorator'
import { activeEditJobsQuery, searchAll, updateEditJob } from '../ts/editing'
import { EditJob, EditJobPriority, EditJobStatus, editPriorityValue } from '@ht-lib/accounts-models'
import EditJobModal from './EditJobModal.vue'
import { getUserWithStore, UserProfileMap } from '../ts/userStore'
import { format, fromUnixTime } from 'date-fns'

const marketLookup = { S: 'Schools', G: 'Grads' }

@Component({
  name: 'EditJobsTable',
  components: {
    EditJobModal
  }
})
export default class extends Vue {
  @Ref() editModal: EditJobModal

  snap !: () => void
  jobs: EditJob[] = []
  loading = true
  users: UserProfileMap = {}
  searchTerm = ''
  loadingSearch = false
  searching = false

  visibleColumns = [
    'title',
    'category',
    'source',
    'market',
    'status',
    'createdAt',
    'headCount',
    'createdBy',
    'assignedTo',
    // 'priority',
    'actions'
  ]

  pagination = {
    sortBy: 'priority',
    descending: false,
    page: 1,
    rowsPerPage: 200
  }

  paginationRowOptions = [50, 200, 500, 1000, 0]

  columns = [
    {
      label: 'Title',
      name: 'title',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.title
    },
    {
      label: 'Category',
      name: 'category',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.actionCategory
    },
    {
      label: 'Priority',
      name: 'priority',
      sortable: true,
      align: 'center',
      hidden: true,
      field: (r: EditJob) => editPriorityValue(r),
      sort: (priorityA: number, priorityB: number, rowA: EditJob, rowB: EditJob) => {
        return priorityB - priorityA ||
          // rowA.title.localeCompare(rowB.title, undefined, { sensitivity: 'base' }) ||
          rowA.createTime - rowB.createTime
      }
    },
    {
      label: 'Source',
      name: 'source',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.jobSource
    },
    {
      label: 'Market',
      name: 'market',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.market
    },
    {
      label: 'Status',
      name: 'status',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.status
    },
    {
      label: 'Date Created',
      name: 'createdAt',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.createTime
    },
    {
      label: 'Head Count',
      name: 'headCount',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.headCount
    },
    {
      label: 'Created By',
      name: 'createdBy',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.createdUser
    },
    {
      label: 'Assigned To',
      name: 'assignedTo',
      sortable: true,
      align: 'center',
      field: (r: EditJob) => r.assignedUserId
    },
    {
      label: 'Actions',
      name: 'actions',
      sortable: true,
      align: 'center'
    }
  ]

  showEditJobModal (job: EditJob | null) {
    this.editModal.showModal(job)
  }

  assignedUser (uid: string) {
    return this.users[uid]?.displayName || 'Unassigned'
  }

  createdUser (uid: string) {
    if (uid === 'SYSTEM') return uid
    return this.users[uid]?.displayName || 'Not Found'
  }

  formatUnixToDate (unix: number) {
    return format(fromUnixTime(unix), 'HH:mm - dd MMM')
  }

  async openJob (job: EditJob) {
    if (!job.assignedUserId || job.status === EditJobStatus.UNASSIGNED) {
      await updateEditJob(job.id, { assignedUserId: this.$auth.user.uid, status: EditJobStatus.IN_PROGRESS })
    }
    if (job.assignedUserId === this.$auth.user.uid && job.status === EditJobStatus.ASSGINED) {
      await updateEditJob(job.id, { status: EditJobStatus.IN_PROGRESS })
    }
    this.$router.push(`/editing/view/${ job.id }`)
  }

  marketString (marketCode: string) {
    return marketLookup[marketCode]
  }

  clearSearch () {
    this.setupSnap()
    this.searching = false
  }

  async search () {
    this.cleanup()
    this.loadingSearch = true
    const docs = await searchAll(this.searchTerm)
    this.setJobs(docs)
    console.log(docs)
    this.loadingSearch = false
    this.searching = true
  }

  async setJobs (jobs: EditJob[]) {
    this.jobs = jobs.sort((a, b) => {
      return (editPriorityValue(b) - editPriorityValue(a)) || a.createTime - b.createTime
    })

    const map: UserProfileMap = {}
    await Promise.all(
      this.jobs.map(async x => {
        if (x.createdUser && x.createdUser !== 'SYSTEM') {
          map[x.createdUser] = await getUserWithStore(x.createdUser)
        }
        if (x.assignedUserId) {
          map[x.assignedUserId] = await getUserWithStore(x.assignedUserId)
        }
      })
    )
    this.users = map
    this.loading = false
  }

  setupSnap () {
    this.cleanup()
    this.snap = activeEditJobsQuery().onSnapshot(snap => {
      this.setJobs(snap.docs.map(x => x.data()))
    })
  }

  cleanup () {
    if (this.snap) {
      this.snap()
    }
  }

  created () {
    this.setupSnap()
  }

  beforeDestroy () {
    this.cleanup()
  }

  jobRowColour (job: EditJob) {
    if (job.status === EditJobStatus.COMPLETE) {
      return 'bg-green-11'
    }
    if (job.priority === EditJobPriority.URGENT) {
      return 'bg-red-11'
    }
    if (job.priority === EditJobPriority.PRIORITY) {
      return 'bg-yellow-11'
    }
    return ''
  }
}
