
import { Component, Vue } from 'vue-property-decorator'
import { User } from '@ht-lib/vue-google-signin'
import { UploadProgress } from '@ht-vue/image-transfer'
import openURL from 'quasar/src/utils/open-url.js';
import { resetUserProfileId } from '@ht-lib/accounts-common'
import { uploader } from '../../upload'
import { userHasRoles, UserRoles } from '@ht-lib/accounts-models'
import { version } from '../../ts/common'

interface Guide {
  name: string
  src: string
}

@Component({
  name: 'Default',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { UploadProgress }
})
export default class extends Vue {
  openURL = openURL
  drawer = true
  uploader = uploader
  guides: Guide[] = [
    { name: 'Uploading Images', src: 'UploadingImagestotheCloud.pdf' },
    { name: 'Committing To The Lab', src: 'CommittingtotheLab.pdf' }
  ]

  get showEditJobs () {
    const profile = this.$auth.user.profile
    if (!profile) return false
    return userHasRoles(profile, [UserRoles.SOFTWARE_GODS, UserRoles.IMAGE_EDITING, UserRoles.IMAGE_EDITING_ADMIN])
  }

  get user (): User {
    return this.$auth.user ?? {
      profile: null,
      email: '',
      name: '',
      uid: '',
      picture: '../assets/sad.svg'
    }
  }

  get version (): string { return version() }

  created (): void {
    this.guides.forEach(x => {
      x.src = process.env.BASE_URL + x.src
    })
  }

  signOut (): void {
    this.$auth.signOut()
    resetUserProfileId()
  }

  appsMenu = [
    { icon: 'mdi-webhook', color: 'bookings', text: 'Bookings', link: 'https://cortex.tempestapps.io' },
    { icon: 'mdi-chart-areaspline', color: 'sales-support', text: 'Sales', link: 'https://salessupport.tempestapps.io' },
    { icon: 'mdi-cloud-upload', color: 'upload', text: 'upload', link: 'https://upload-tempestapps-io-test.firebaseapp.com' },
    { icon: 'mdi-file-document-box-check', color: 'data', text: 'Data', link: 'https://job-tempestapps-io-test.firebaseapp.com' },
    { icon: 'mdi-image-search', color: 'qc', text: 'QC', link: 'https://job-tempestapps-io-test.firebaseapp.com' }
  ]
}
