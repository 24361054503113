
import { Component, Ref, Vue } from 'vue-property-decorator'
import { ImageEntry, EditJob, EditJobStatus, UserProfile, isTypeMultiValue, ActionCategory, ImageStatus } from '@ht-lib/accounts-models'
import { editJobDoc, updateEditJob } from '../ts/editing'
import DownloadRequestTable from '../components/DownloadRequestTable.vue'
import UploadEditJob from '../components/UploadModal.vue'
import { getImagesForEditJob } from '../ts/images'
import EditJobModal from '../components/EditJobModal.vue'
import { getUserWithStore } from '../ts/userStore'
import DownloadRequestModal from '../components/DownloadRequestModal.vue'
import { LongRefSelectMap, Unlisten, batchMergeImages } from '@ht-lib/image-util'
import { ImagesPreview } from '@ht-vue/image-transfer'

interface Banner{
  className: string
  text: string
}

interface Button {
  color: string
  text: string
  action: () => void
}

@Component({
  name: 'EditJobView',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { DownloadRequestTable, UploadEditJob, EditJobModal, DownloadRequestModal, ImagesPreview }
})
export default class extends Vue {
  @Ref() editModal!: EditJobModal
  @Ref() downloadRequestModal!: DownloadRequestModal

  loading = true
  loadFailed = false
  images: ImageEntry[] = []
  imagesLoaded = false

  job: EditJob = null
  createdBy: UserProfile = null
  assignedUser: UserProfile = null
  jobUnlisten: () => void
  imagesUnlisten: Unlisten

  showPreview = false
  showUploadModal = false
  showCloseJobModal = false
  selectedImages: LongRefSelectMap = {}

  get finalImages () {
    const some = Object.values(this.selectedImages).some(x => x)
    if (!some) return this.images
    return this.images.filter(x => this.selectedImages[x.longRef])
  }

  prepareDownload () {
    this.downloadRequestModal.showModal({
      entries: this.finalImages,
      market: this.job.market,
      title: this.job.title,
      canUsePrep: true,
      addEditedFolder: true,
      contextId: this.job.id,
      onlyFlagged: this.job.showOnlyFlagged
    })
  }

  edit () {
    if (this.job) {
      this.editModal.showModal(this.job)
    }
  }

  get isComplete () {
    return this.job.status === EditJobStatus.COMPLETE
  }

  get dataString () {
    if (isTypeMultiValue(this.job)) {
      return (this.job.data as []).join(', ')
    }
    return this.job.data
  }

  get isWriteMode () {
    return this.job.assignedUserId === this.$auth.user.uid && !this.isComplete
  }

  assignMe () {
    updateEditJob(this.job.id, {
      assignedUserId: this.$auth.user.uid
    })
  }

  async closeJob () {
    if (this.job.createdUser === 'SYSTEM') {
      console.log('System created job, marking all images as NONE status')
      await batchMergeImages(this.images.map(x => ({ longRef: x.longRef, status: ImageStatus.NONE })))
    }
    await updateEditJob(this.job.id, {
      status: EditJobStatus.COMPLETE,
      actionCategory: ActionCategory.NONE
    })
    this.$router.push('/editing')
  }

  loadImages () {
    if (this.imagesUnlisten) this.imagesUnlisten()
    this.images = []
    this.imagesLoaded = false
    this.imagesUnlisten = getImagesForEditJob(this.job, (images, shouldAppend) => {
      if (shouldAppend) {
        images.filter(Boolean).forEach(newImage => {
          const index = this.images.findIndex(image => image.longRef === newImage.longRef)
          if (index > -1) {
            this.images.splice(index, 1, newImage)
          } else {
            this.images.push(newImage)
          }
        })
      } else {
        this.images = images.filter(Boolean)
      }

      this.imagesLoaded = true
    })
  }

  unassignUser () {
    console.log(this.job)
    updateEditJob(this.job.id, {
      assignedUserId: null
    })
  }

  // eslint-disable-next-line getter-return
  get statusChangeButton (): Button | undefined {
    if (this.job.status === EditJobStatus.IN_PROGRESS) {
      return {
        color: 'warning',
        action: async () => updateEditJob(this.job.id, { status: EditJobStatus.HOLD }),
        text: 'Put On Hold'
      }
    }
    if (this.job.status === EditJobStatus.HOLD) {
      return {
        color: 'positive',
        action: async () => updateEditJob(this.job.id, { status: EditJobStatus.IN_PROGRESS }),
        text: 'Resume'
      }
    }
    if (this.job.status === EditJobStatus.COMPLETE) {
      return {
        color: 'positive',
        action: async () => updateEditJob(this.job.id, { status: EditJobStatus.IN_PROGRESS }),
        text: 'Open Job'
      }
    }

    return undefined
  }

  get banner (): Banner {
    if (!this.job) return null
    if (!this.job.assignedUserId) {
      return {
        className: 'bg-warning text-black',
        text: 'No one is assigned to this edit job. Do you want to assign yourself and let others know you\'re working on it?'
      }
    }
    if (this.job.assignedUserId !== this.$auth.user.uid) {
      return {
        className: 'bg-negative text-white',
        text: "You're not assigned to this job"
      }
    }

    return null
  }

  async setupSnap (jobId: string) {
    return new Promise<boolean>((resolve) => {
      this.jobUnlisten = editJobDoc(jobId).onSnapshot(snap => {
        const data = snap.data()
        if (!data) {
          this.loadFailed = true
          return resolve(false)
        }

        this.job = data
        console.log('Viewing job: ', snap.data())
        resolve(true)
      })
    })
  }

  async setupAssignedUser () {
    if (!this.job.assignedUserId) {
      this.assignedUser = null
      return
    }

    this.assignedUser = await getUserWithStore(this.job.assignedUserId || '')
  }

  async created () {
    const jobId = this.$route.params.jobId
    if (!jobId) {
      this.loadFailed = true
      this.loading = false
    }

    const success = await this.setupSnap(jobId)
    if (!success) return

    const [createdBy] = await Promise.all([
      this.job.createdUser !== 'SYSTEM' && getUserWithStore(this.job.createdUser),
      this.setupAssignedUser(),
      this.loadImages()
    ])
    this.createdBy = createdBy
    this.loading = false
  }

  beforeDestroy () {
    if (this.imagesUnlisten) this.imagesUnlisten()
    if (this.jobUnlisten) this.jobUnlisten()
  }
}
