import { UploadImageEvent, UploadEvent, UploadManager } from '@ht-lib/image-util'
import Notify from 'quasar/src/plugins/Notify.js';
import Vue from 'vue'

const onJobFinish: UploadEvent = async () => {
  Notify.create('Done')
  return await Promise.resolve()
}

const onImageError: UploadImageEvent = async (uploadJob, img) => {
  Notify.create({
    message: `Failed to upload an image: ${ img.longRef } for job: ${ uploadJob.description }. Please start again, if this issue persists please get in conatct with dev support.`,
    timeout: 0,
    actions: [{
      label: 'Start again', color: 'white', handler: () => { window.location.reload() }
    }],
    type: 'negative'
  })

  return await Promise.resolve()
}
export const uploader = Vue.observable(new UploadManager({
  onJobFinish,
  onImageError,
  throwError: true
}))
