export enum PaymentStatus {
  AUTHORISED = 'AUTHORISED', // This one comes in from smartpay on the grads website
  CANCELLED = 'CANCELLED', // This one comes in from smartpay on the grads website
  AUTHORISATION = 'AUTHORISATION',
  ERROR = 'ERROR',
  FAIL = 'FAIL',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUND = 'REFUND',
  REFUND_WITH_DATA = 'REFUND_WITH_DATA',
  REFUSED = 'REFUSED',

  // The following are potential Cybersource responses
  AUTHORIZED = 'AUTHORIZED',
  PARTIAL_AUTHORIZED = 'PARTIAL_AUTHORIZED',
  REVERSED = 'REVERSED',
  VOIDED = 'VOIDED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  CHALLENGE = 'CHALLENGE',
  AUTHENTICATION_SUCCESSFUL = 'AUTHENTICATION_SUCCESSFUL',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  AUTHORIZED_PENDING_REVIEW = 'AUTHORIZED_PENDING_REVIEW',
  AUTHORIZED_RISKDECLINED = 'AUTHORIZED_RISK_DECLINED',
  DECLINED = 'DECLINED',
  PENDING_AUTHENTICATION = 'PENDING_AUTHENTICATION',
  INVALID_REQUEST = 'INVALID_REQUEST',
  SERVER_ERROR = 'SERVER_ERROR',
}

export interface SmartPayAmount {
  currency: string
  /** @type {number} Amount as an integer (e.g. �123.45 would be `value: 12345`) */
  value: number
}

export enum WebOrderOrigin {
  SCHOOLS = 'Schools web order',
  GRADS = 'Grads web order',
  EVENTS = 'Events web order',
  GROUPS = 'Groups web order',
  TICKETING = 'Ticketing Issue',
  DOFE = 'DofE web order',
  BOOKINGS = 'Bookings web order',
  GOWNS = 'Gowns web order',
  NONESET = ' No value set'
}

export interface NewOrderEmailMessage {
  origin: WebOrderOrigin
  dataset: any
  useTempestLivery: boolean
  transactionIds?: string[]
}

export interface SmartPayAdditionalData {
  expiryDate: string
  NAME1: string
  totalFraudScore: string
  cardSummary: string
  NAME2: string
  'fraudCheck-6-ShopperIpUsage': string
}

export interface SmartPayNotificationRequestItem {
  additionalData?: SmartPayAdditionalData
  amount: SmartPayAmount
  eventCode: PaymentStatus
  eventDate: string
  merchantAccountCode: string
  merchantReference: string
  paymentMethod: string
  pspReference: string
  reason: string
  success: string
}

export interface SmartPayNotificationItem {
  NotificationRequestItem: SmartPayNotificationRequestItem
}

export interface SmartPayRecord {
  live: string
  notificationItems: SmartPayNotificationItem[]
}
