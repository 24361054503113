
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EditJob, EditDataType, EditJobStatus, isTypeMultiValue, EditJobPriority, ActionCategory } from '@ht-lib/accounts-models'
import { generate } from 'short-uuid'
import { getUnixTime } from 'date-fns'
import { isEqual, cloneDeep } from 'lodash'
import { updateEditJob, addEditJob } from '../ts/editing'
import { getImageEditingUsers, UserProfileWithId } from '../ts/userStore'
import { EditJobSource } from '@ht-lib/accounts-models/src'

function getDefaultEditJob (): EditJob {
  return {
    errorText: '',
    id: generate(),
    price: 0,
    editedBy: [],
    headCount: null,
    userInputTimeTaken: null,
    priority: EditJobPriority.NORMAL,
    actionCategory: ActionCategory.NONE,
    createTime: getUnixTime(new Date()),
    createdUser: '',
    completeTime: null,
    dataType: EditDataType.REFS,
    data: [],
    title: '',
    jobSource: EditJobSource.OTHER,
    actionNotes: '',
    showOnlyFlagged: false,
    assignedUserId: null,
    status: EditJobStatus.UNASSIGNED,
    notes: '',
    market: 'S'
  }
}

@Component({
  name: 'AddEditJobModal'
})
export default class extends Vue {
  @Prop({ default: true }) showAssignedTo: boolean
  show = false
  job: EditJob = getDefaultEditJob()
  startJob: EditJob | null = null
  dataTypes = Object.values(EditDataType)
  jobSources = Object.values(EditJobSource)
  priorityOptions = Object.values(EditJobPriority)
  actionOptions = Object.values(ActionCategory)
  imageEditingUsers: UserProfileWithId[] = []

  async created () {
    this.imageEditingUsers = await getImageEditingUsers()
  }

  get assignedUserDisplayValue () {
    console.log(this.job.assignedUserId)
    return this.imageEditingUsers.find(usr => usr.id === this.job.assignedUserId)?.displayName || ''
  }

  showModal (job: EditJob | null) {
    this.startJob = job
    console.log('Opening Edit Job modal with job: ', job)
    if (job) {
      this.job = cloneDeep(this.startJob)
    } else {
      this.job = getDefaultEditJob()
    }
    this.show = true
    this.updateData()
  }

  get editMode () {
    return !!this.startJob
  }

  get isMultiType () {
    return isTypeMultiValue(this.job)
  }

  get saveAvailable () {
    return this.hasChanged && this.job.data.length && this.job.title
  }

  get hasChanged () {
    return !isEqual(this.startJob, this.job)
  }

  @Watch('job.dataType')
  updateData () {
    if (this.isMultiType && typeof this.job.data === 'string') {
      this.job.data = []
    } else if (!this.isMultiType && typeof this.job.data !== 'string') {
      this.job.data = ''
    }
  }

  addItem () {
    this.job.data = this.job.data.concat('')
    console.log(this.job.data)
  }

  editItem (index: number, val: string) {
    if (typeof this.job.data === 'string') return
    this.job.data[index] = val.toUpperCase()
    this.$forceUpdate()
  }

  editDataString (str: string) {
    if (typeof this.job.data !== 'string') return
    this.job.data = str.toUpperCase()
  }

  removeItem (i: number) {
    if (typeof this.job.data === 'string') return
    this.job.data.splice(i, 1)
  }

  async save () {
    console.log('Saving job data: ', this.job)
    if (this.editMode) {
      await updateEditJob(this.job.id, this.job)
    } else {
      this.job.createdUser = this.$auth.user.uid
      if (this.job.assignedUserId) {
        this.job.status = EditJobStatus.ASSGINED
      }
      await addEditJob(this.job)
    }
    this.show = false
  }
}
