
import { DownloadRequestFileNameKey, DownloadSettings, ImageEntry, ImageFileType, ImagePrepSettings, ImageStatus, Market } from '@ht-lib/accounts-models'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { fileNameOptions, FileNameUserFormatted } from '../ts/download'
import { DownloadRequest } from '@ht-web/firebase-adapter'
import { ImagePrepSettings as ImagePrepSettingsComp, ImagesPreview } from '@ht-vue/image-transfer'

interface RequestModalSettings{
  entries: ImageEntry[]
  title: string
  canUsePrep: boolean
  addEditedFolder: boolean
  contextId: string
  onlyFlagged: boolean
  market: Market
}

@Component({
  name: 'DownloadRequestModal',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { ImagePrepSettingsComp, ImagesPreview }
})
export default class DownloadRequestModal extends Vue {
  show = false
  downloadName = 'My Download'
  fileNames: FileNameUserFormatted = 'Ref'
  fileNameOptions: FileNameUserFormatted[] = Object.keys(fileNameOptions) as FileNameUserFormatted[]
  entries: ImageEntry[] = []
  contextId = ''
  rawImages = false
  classFolders = true
  canUsePrep = true
  onlyFlagged = false
  onlyPreferred = false
  onlyAccepted = false
  addEditedFolder = false
  showCannotProcessImagePreview = false
  market: Market = 'S'

  prep: ImagePrepSettings = {
    x: 2400,
    y: 3000,
    adjust: true,
    nexuscrop: true,
    sharpen: false,
    needscc: true,
    watermark: false,
    xinch: 8,
    yinch: 10,
    watermarkopacity: 0.6,
    watermarkcolour: '#ffffff',
    filetype: ImageFileType.JPG,
    quality: 85
  }

  get cannotBeProcessed (): ImageEntry[] {
    const nameKey: DownloadRequestFileNameKey = fileNameOptions[this.fileNames] ?? 'ref'
    return this.finalEntries.filter(image => {
      const fileNameValue = image[nameKey]
      return typeof fileNameValue !== 'string' && typeof fileNameValue !== 'number'
    })
  }

  get cardWidth (): string {
    return this.cannotBeProcessed.length > 0 ? 'width: 80vw; max-width: 80vw' : 'width:350px;'
  }

  showModal ({ entries, title, canUsePrep, addEditedFolder, contextId, onlyFlagged, market }: RequestModalSettings): void {
    this.show = true
    this.entries = entries
    this.contextId = contextId
    this.downloadName = title
    this.canUsePrep = canUsePrep
    this.rawImages = true
    this.onlyFlagged = onlyFlagged
    this.addEditedFolder = addEditedFolder
    this.market = market
    if (market === 'G') {
      this.classFolders = false
    }
  }

  get finalEntries (): ImageEntry[] {
    return this.entries.filter(x => {
      if (x == null) return false
      let show = true
      if (this.fileNames === 'Pupil ID') {
        show = show && x.shotTypeId === 1
      }
      if (this.onlyFlagged) {
        show = show && x.status === ImageStatus.FLAGGED
      }
      if (this.onlyPreferred) {
        show = show && x.isPreferred
      }
      if (this.onlyAccepted) {
        show = show && x.status !== ImageStatus.REJECTED
      }
      return show
    })
  }

  get longRefs (): string[] {
    return this.finalEntries.map(x => x.longRef)
  }

  async start (): Promise<void> {
    const nameKey: DownloadRequestFileNameKey = fileNameOptions[this.fileNames] ?? 'ref'
    const settings: DownloadSettings = {
      userId: this.$auth.user.uid,
      longRefs: this.longRefs,
      zipDownloadName: this.downloadName,
      prepSettings: this.rawImages ? null : this.prep,
      classFolders: this.market === 'S' ? this.classFolders : false,
      addEditedFolder: this.addEditedFolder,
      contextId: this.contextId,
      keyName: this.fileNames
    }

    await DownloadRequest.add(settings, nameKey)
  }
}
